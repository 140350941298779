import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTicket, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Button, Container, Row, Col, Alert, Modal, Spinner, Form} from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import services from "../services/Services";
import styles from "../utils/Styles";
import singletonSession from "../services/IntanceSession";
import Datatable from "react-data-table-component";
import UserService from "../services/UserService";
import DataTableExtensions from 'react-data-table-component-extensions';
import "react-data-table-component-extensions/dist/index.css";
import InstanceDataUser from "../services/InstanceDataUser";

const CustomLoader = () => (
  <div style={{ padding: '24px' }}>
      <Spinner animation="border" variant="primary"/>
      &nbsp;<b><label>Cargando...</label></b>
  </div>
);

const SelectGroup = ({id, label, help, ...props}) => {
    return (

      <Form.Group as={Row} controlId={id}>
          <Col sm="7">
              <Form.Select {...props} />
              {help}
          </Col>
      </Form.Group>

    )
}

class AdminTokens extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataClients: [],
            dataTokens:[],
            idCliente:'',
            isVisibleSelect : 0,
            user : '',
            rol:'',
            token:'',
            vigencia:'',
            UserName:'',
            AccessUuid:'',
            filter: false,
            openModal: false,
            showAlertCreateToken: false,
            showAlertDeleteToken: false,
            showAlertUser: false,
            valueSel: "admin",
            loading:true,
            select: ""
        }
    }

    columns = [
        {name: "ID Cliente", selector: row => row.IDClient, sortable: true, width: '15%', export: false},
        {name: "Nombre", selector: row => row.UserName, sortable: true, width: '20%', export: false},
        {name: "Fecha Creación", selector: row => row.AccessCreateAt, sortable: true, width: '25%', export: false},
        {name: "Vigencia", selector: row => row.Expires, sortable: true, width: '25%', export: false},
        {
            name: "Eliminar", button: true, width: '15%', export: false,
            ignoreRowClick: false,
            allowOverflow: true,
            cell: (row) => <FontAwesomeIcon icon={faTrash} className="hand" onClick={() => {
                this.setState({
                    openModal: true,
                    UserName:row.UserName,
                    AccessUuid: row.AccessUuid,
                    vigencia:  row.Expires
                })
            }}/>

        }
    ]

    onDelete =()=>  {
        const body = {
            "Username": this.state.UserName,
            "AccessUuid": this.state.AccessUuid
        }
        services.deleteToken(body).then(response => {
            this.setState({
                openModal: false,
                showAlertDeleteToken: true})
            if(this.state.rol === 'Administrador') {
                this.getTokens()
            }else{
                this.getUserTokens()
            }
        }).catch(error =>  console.log(error))
    }

    onCreateToken = ( e) => {
        if(this.state.rol === 'Administrador' && this.state.select === ""){
            this.setState({showAlertUser: true})
        }else {
            singletonSession.getInstance().post("/users/client").then((response) => {
                this.setState({token: response.data.AccesToken})
                this.setState({showAlertCreateToken: true})
                if (this.state.rol === 'Administrador') {
                    this.getTokens()
                } else {
                    this.getUserTokens()
                }
            }).catch(error => console.log(error))
        }
    }

    selectClient = ( e) => {
        console.log(e)
        let name = e.target.options[e.target.selectedIndex].getAttribute('title')
        if(name !== null) {
            this.setState({select: name})
            singletonSession.setUser(name)
        }else{
            this.setState({select : ""})
        }
    }

    getClients(){
        services.getUsersMysql().then(response => {
            this.setState({dataClients: response.data})
        })
    }

    getTokens(){
        services.getAllTokens().then(response => {
            this.setState({dataTokens: response.data, loading: false})
        })
    }

    getUserTokens(){
        console.log(this.state)
        let body = {
            "Username": this.state.user
        }
        services.getUserTokens(body).then(response => {
            this.setState({dataTokens: response.data, loading: false})
        })
    }

    onCloseModal = () => this.setState({openModal: false})

    componentDidMount() {
        let user = UserService.gerUser()
        this.setState({user: user})
        this.getRol()
    }

    getRol(){
        let name = InstanceDataUser.getRol()
        this.setState({rol: name})
        if(name === 'Administrador'){
            this.setState({isVisibleSelect: 1,filter: true})
            this.getTokens()
            this.getClients()
        } else {
            this.setState({isVisibleSelect: 0,filter: false})
            setTimeout(()=>{
                this.getUserTokens()
            }, 100);

        }
        /*services.getRoleById(UserService.getUserID()).then(response=>{
            let {id,name} = response.data[0]

        }).catch((e)=>console.log("icintracom error:::",e.message))*/
    }


    render() {

        return(
          <>
              <Container fluid>
                  <Alert show={this.state.showAlertCreateToken} variant="success" dismissible
                         onClose={() => this.setState({showAlertCreateToken: false})}
                         style={{margin: "2rem"}}>

                      <Alert.Heading>Token</Alert.Heading>
                      <p>
                          ¡Se creo token con exitó!
                      </p>
                      <p>
                          <hr/>
                          <Row>
                              <Col sm={1}></Col>
                              <Col sm={10}>
                                  <label style={styles.token}>
                                      {this.state.token}
                                  </label>
                              </Col>
                              <Col sm={1}></Col>
                          </Row>
                          <hr/>
                          <Row>
                              <Col sm={5}></Col>
                              <Col sm={7}>
                                  <label style={styles.tokenNote}>
                                      Copie este token. Por razones de seguridad, no podrá visualizarse en su cuenta.
                                  </label>
                              </Col>
                          </Row>
                      </p>

                  </Alert>
                  <Alert show={this.state.showAlertDeleteToken} variant="success" dismissible
                         onClose={() => this.setState({showAlertDeleteToken: false})}
                         style={{margin: "2rem"}}>

                      <Alert.Heading>Token</Alert.Heading>
                      <p>
                          ¡Se eliminó token con exitó!
                      </p>
                      <hr/>

                  </Alert>
                  <Alert show={this.state.showAlertUser} variant="warning" dismissible
                         onClose={() => this.setState({showAlertUser: false})}
                         style={{margin: "2rem"}}>

                      <Alert.Heading>Token</Alert.Heading>
                      <p>
                          ¡Por favor seleccione un usuario!
                      </p>
                      <hr/>

                  </Alert>
                  <Row className="justify-content-md-center">
                      <Col sm={1}></Col>
                      <Col sm={10}>
                          <div style={styles.columnText}>
                              <h1 id="lbl_title_admin_tokens">Administración de Tokens</h1>
                          </div>
                      </Col>
                      <Col sm={1}></Col>
                  </Row>
                  {

                      this.state.loading === false ?
                        <Row className="justify-content-md-center">
                            <Col sm={1}></Col>
                            <Col sm={3}>
                                <label>Total tokens : <b>{this.state.dataTokens.length}</b></label>
                            </Col>
                            <Col sm={5}>
                                {

                                    this.state.isVisibleSelect === 1 ?
                                      <SelectGroup
                                        required
                                        id="select"
                                        type="select"
                                        placeholder="Seleccione usuario"
                                        onChange={(e) => {
                                            this.selectClient(e)
                                        }}
                                      >

                                          <option value="">Seleccione un usuario...</option>
                                          {
                                              this.state.dataClients.map((option,key) => {
                                                  return  <option key={key} value={option.value}
                                                                  title={option.short_name}>{option.name}</option>;
                                              })
                                          }
                                      </SelectGroup>
                                      : null

                                }
                            </Col>
                            <Col sm={3}>
                                <Button  size="sm" variant="primary" onClick={this.onCreateToken} >
                                    <FontAwesomeIcon icon={faTicket}/>
                                    &nbsp;Genere un nuevo token de acceso
                                </Button>
                            </Col>
                        </Row>
                        : <Row className="justify-content-md-center"> </Row>

                  }
              </Container>
              <Container fluid>
                  <Row>
                      <Col sm={12}>
                          <div style={styles.topRow}></div>
                      </Col>
                  </Row>
                  <p></p>
                  <Row className="justify-content-md-center">
                      <Col sm={1}></Col>
                      <Col sm={10}>
                          <DataTableExtensions
                            columns={this.columns}
                            data={this.state.dataTokens}
                            exportHeaders={false}
                            print={false}
                            export={false}
                            filter={this.state.filter}
                            filterPlaceholder={"Buscar Usuario"}
                            fileName={"UserName"}>
                              <Datatable
                                columns={this.columns}
                                data={this.state.dataTokens}
                                pagination
                                responsive
                                striped
                                defaultSortField="UserName"
                                progressPending={this.state.loading}
                                progressComponent={<CustomLoader />}
                                noDataComponent={"No existen registros"}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Registros por página:',
                                    rangeSeparatorText: 'de'
                                }}
                              />
                          </DataTableExtensions>
                      </Col>
                  </Row>
              </Container>

              <Modal show={this.state.openModal} onHide={this.onCloseModal}>
                  <Modal.Header closeButton>
                      <Modal.Title>Eliminar Token</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="pure-form">
                      <div>
                          ¿Eliminar el token de  <b>{this.state.UserName}</b> con vigencia <b>{this.state.vigencia}</b>?
                      </div>
                      <p/>
                      <Modal.Footer>
                          <Button variant="primary" onClick={this.onDelete}>Aceptar</Button>
                          <Button variant="secondary" onClick={this.onCloseModal}>Cancelar</Button>
                      </Modal.Footer>
                  </Modal.Body>

              </Modal>
          </>
        )
    }

}

export default AdminTokens;