import React, {useState, useEffect, useRef} from "react";
import {Alert, Button, Col, Form, FormControl, Modal, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import services from "../../services/Services";


const FieldGroup = ({id, label, help, ...props}) => {
    return (

      <Form.Group as={Row} className="mb-2" controlId={id}>
          <Form.Label column sm="5">
              {label}
          </Form.Label>
          <Col sm="7">
              <FormControl {...props} />
              {help}
          </Col>
      </Form.Group>

    )
}

const SelectGroup = ({id, label, help, ...props}) => {
    return (

      <Form.Group as={Row} className="mb-2" controlId={id}>
          <Form.Label column sm="5">
              {label}
          </Form.Label>
          <Col sm="7">
              <Form.Select {...props} />
              {help}
          </Col>
      </Form.Group>

    )
}


const AddUser = ({roles, dataUsers, getUsers,handlerOpenAlertAdd}) => {

    const [dataRoles, setDataRoles] = useState([])
    const [show, setShow] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [error, setError] = useState(false)
    const [isVisibleClientID, setIsVisibleClientID] = useState("0")


    const [dataFormAddUser, setDataFormAddUser] = useState({
        userName: '',
        email: '',
        name: '',
        lastName: '',
        rolID: '',
        rolName: '',
        IDCLIENTE: '',
        TIPOUSUARIO: '',
        id_client: ''
    })

    const formRef = useRef()

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseAlert = () => setShowAlert(false)
    //const handleShowAlert = () => setShowAlert(true)

    const handleCloseError = () => setError(false)
    const handleShowError = () => setError(true)

    /*
    * método para crear el usuario
    * */
    const onAddUser = (event) => {

        if (event) event.preventDefault();

        const userExists = dataUsers.some((user) => user.FIRST_NAME === dataFormAddUser.name);

        if(userExists){
            handleShowError()
        } else {
            console.log(dataFormAddUser)
            const bodyAdd = {
                "name": dataFormAddUser.name,
                "short_name": dataFormAddUser.userName,
                "id_client": dataFormAddUser.IDCLIENTE,
                "email": dataFormAddUser.email
            }
            //console.log("dataFormAddUser:::::", dataFormAddUser)
            services.addUserMysql(bodyAdd).then(responseMy => {
                let id = responseMy.data.id.IdCliente
                addUserKey(id)
            })
        }
    }



    /*
    * método que agregar usuario en keycloak el password incial
    * */
    const addUserKey = (userID) => {
        let body = {
            "enabled": true,
            "firstName": dataFormAddUser.name,
            "lastName": dataFormAddUser.lastName,
            "email": dataFormAddUser.email,
            "username": dataFormAddUser.userName,
            "requiredActions": ["UPDATE_PASSWORD"],
            "attributes": {
                "IDCLIENTE":[dataFormAddUser.IDCLIENTE],
                "TIPOUSUARIO":[dataFormAddUser.TIPOUSUARIO],
                "IDMYSQL" :[userID]
            }
        }
        services.addUser(body).then((response) => {
            const id = response.headers.location
            const userTxt = id.split('/').pop()

            if (response.status === 201) {
                onSetPassword(userTxt)
            }
        }).catch((error) => {
            if (error.response.status === 409) {
                let body={
                    "IdCliente": userID
                }
                services.deleteUserMysql(body)
            }
            handleShowError()
        })
    }

    /*
    * método que asigna el password incial
    * */
    const onSetPassword = (userID) => {
        const body = {"type": "password", "value": "icintracom", "temporary": true}
        services.resetPassword(userID, body).then(response => {
            onSetRolUser(userID)
        }).catch(error => console.log("ICIntracom", error.message))
    }

    /**
     * método para asignar el rol
     *
     */
    const onSetRolUser = (userID) => {
        let body = [{
            "id": dataFormAddUser.rolID,
            "name": dataFormAddUser.rolName,
            "composite": false,
            "clientRole": true,
            "containerId": "2758d422-8dff-4b47-9706-40559e829d47"
        }]
        services.setRoleUser(userID, body).then(response => {
            onSetRolRealm(userID)
        }).catch(error => console.log("ICIntracom", error.message))
    }

    /**
     * método para asignar el rol al realm, para poder ejecutar los servicios del keycloak
     *
     */
    const onSetRolRealm = (userID) => {
        let body = [{
            "id": "fea79e94-0a22-4f44-af60-639c38f9f109",
            "name": "realm-admin",
            "composite": true,
            "clientRole": true,
            "containerId": "6c4c80bc-b84e-4c70-add3-6fc2f8803eed"
        }]
        services.setRoleRealm(userID, body).then(response => {
            //handleShowAlert()
            setDataFormAddUser({
                userName: '',
                email: '',
                name: '',
                lastName: '',
                rolID: '',
                rolName: '',
                IDCLIENTE: '',
                TIPOUSUARIO: '',
                id_client: ''
            })

            formRef.current.reset();
            setTimeout(() => {
                handleClose()
                handlerOpenAlertAdd()
                getUsers()
            }, 1000)

        }).catch(error => console.log("ICIntracom", error.message))
    }


    /*
    * componente que permite reusar el alert
    * */
    const AlertAddUser = ({title, text, show, close, type}) => {
        return (
          <Alert dismissible
                 variant={type}
                 show={show}
                 onClose={close}
                 style={{margin: "2rem"}}>

              <Alert.Heading>{title}</Alert.Heading>
              <hr/>
              <p>
                  {text}
              </p>
          </Alert>
        )
    }

    /*
    * estado de la función que se usa para agregar los roles que llegan en el props de la función
    * */
    useEffect(() => {
        setDataRoles(roles)
    }, [roles])


    /**
     * metodo que regresa el componente (formulario) dentro del modal para crear el usuario
     * */
    return (
      <>
          <Button id="btnOpenModalUser" size="sm" variant="primary" onClick={handleShow}>
              <FontAwesomeIcon icon={faUser}/>
              &nbsp;Agregar Usuario
          </Button>

          <Modal show={show} onHide={handleClose}>

              <AlertAddUser title={"Usuario"}
                            text={"¡Se agrego exitosamente el usuario!"}
                            show={showAlert}
                            close={handleCloseAlert}
                            type={"success"}/>

              <AlertAddUser title={"Error"}
                            text={"¡El usuario ya existe!"}
                            show={error}
                            close={handleCloseError}
                            type={"danger"}/>

              <Modal.Header closeButton>
                  <Modal.Title>Agregar Usuario</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                  <fieldset className="field_set">
                      <Form ref={formRef} onSubmit={onAddUser}>
                          <SelectGroup
                            required
                            id="slTipoUsuario"
                            type="select"
                            label="Tipo Usuario *"
                            placeholder="rol"
                            defautlValue={isVisibleClientID}
                            onChange={(e) => {
                                let text = e.target.options[e.target.selectedIndex].getAttribute('title')
                                setDataFormAddUser({...dataFormAddUser, TIPOUSUARIO: text})
                                setIsVisibleClientID(e.target.value)
                            }}>

                              <option value="">Seleccione una opción...</option>
                              <option value="1" title="Cliente">Cliente</option>
                              <option value="2" title="Icintracom">Icintracom</option>

                          </SelectGroup>

                          {

                              isVisibleClientID === '1' ?
                                <FieldGroup
                                  required
                                  id="frmClientID"
                                  type="text"
                                  label="ID Cliente *"
                                  placeholder="ID Cliente"
                                  val={dataFormAddUser.IDCLIENTE}
                                  onChange={(e) => setDataFormAddUser({
                                      ...dataFormAddUser,
                                      IDCLIENTE: e.target.value
                                  })}
                                />
                                : null

                          }

                          <FieldGroup
                            required
                            id="frmUserNamet"
                            type="text"
                            label="Username *"
                            placeholder="Username"
                            val={dataFormAddUser.userName}
                            onChange={(e) => setDataFormAddUser({...dataFormAddUser, userName: e.target.value})}
                          />

                          <FieldGroup
                            required
                            id="frmEmail"
                            type="email"
                            label="Email *"
                            placeholder="Email"
                            val={dataFormAddUser.email}
                            onChange={(e) => setDataFormAddUser({...dataFormAddUser, email: e.target.value})}

                          />
                          <FieldGroup
                            required
                            id="frmFirstName"
                            type="text"
                            label="Nombre *"
                            placeholder="Nombre"
                            val={dataFormAddUser.name}
                            onChange={(e) => setDataFormAddUser({...dataFormAddUser, name: e.target.value})}

                          />
                          <FieldGroup
                            id="frmLastName"
                            type="text"
                            label="Apellido"
                            placeholder="Apellido"
                            val={dataFormAddUser.lastName}
                            onChange={(e) => setDataFormAddUser({...dataFormAddUser, lastName: e.target.value})}

                          />
                          <SelectGroup
                            required
                            id="slRol"
                            type="select"
                            label="Rol *"
                            placeholder="rol"
                            val={dataFormAddUser.rolID}
                            onChange={(e) => {
                                let text = e.target.options[e.target.selectedIndex].getAttribute('title')
                                setDataFormAddUser({...dataFormAddUser, rolName: text, rolID: e.target.value})
                            }}
                          >

                              <option value="">Seleccione una opción...</option>
                              {
                                  dataRoles.map((e, key) => {
                                      return <option title={e.name} key={key} value={e.id}>{e.name}</option>;
                                  })
                              }
                          </SelectGroup>

                          <Form.Text id="passwordHelpBlock" muted>
                              Recuerde que el password es en general "icintracom",
                              el usuario al entrar la primera vez, se le pedirá cambiar la contraseña.
                          </Form.Text>
                          <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose}>
                                  Cerrar
                              </Button>
                              <Button variant="primary" type="submit">
                                  Aceptar
                              </Button>
                          </Modal.Footer>
                      </Form>
                  </fieldset>
              </Modal.Body>

          </Modal>


      </>
    )

}

export default AddUser