const styles = {
    input: {
        color: "#3D3D3D",
        background: "transparent",
        fontSize: "13px",
        outline: "none",
        border: "0px none "
    },
    topRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid lightgray",
        paddingLeft: 10,
        paddingBottom: 15
    },
    columnText: {
        color: "#000",
        fontSize: "0.8rem",
        fontWeight: "bold",
        paddingTop: 20,
        paddingBottom: 30
    },
    token: {
        overflowWrap: "break-word",
        display: "block",
        padding: 10
    },
    tokenNote: {
        fontSize: "15px"
    },
    body:{
        backgroundColor:  "rgba(29,41,76,1)",
        width:"100%",
        height: "100%",
        color:"#FFFFFF"
    }
}

export default styles;