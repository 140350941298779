import axios from 'axios';

class InstanceSession{

  instance
  cookie
  username
  password
  credentials = btoa(this.username + this.password);


  getInstance(){
    return this.instance;
  }

  setCookie(cookie){
    this.cookie = cookie
  }

  setUser(user){
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_HOST_APIS+":"+process.env.REACT_APP_PORT_SESSION+"/api",
      auth: {
        username: user,
        password: this.password
      }
    })
  }

}

const singletonSession = new InstanceSession();



export default singletonSession;