import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPowerOff,faUser} from "@fortawesome/free-solid-svg-icons";
import UserService from "../services/UserService";
import { Outlet , useNavigate} from 'react-router-dom';
import Menu from "./components/OptionMenu";
import services from "../services/Services";
import AlertSession from "./components/AlertSession";


const Home = ({Component}) => {

  const navigate = useNavigate();

  const initSession = () => {
    setTimeout(()=>{
      navigate('/')//produccion
      //navigate('/pruebasicm/') //pruebas
    }, 0);
  }

  const camelize = (text)  => {
    text = text.replace(/[-_\s.]+(.)?/g, (_, c) => c ? c.toUpperCase() : '');
    return text.substr(0, 1).toUpperCase() + text.substr(1);
  }


  return(
    <div id="layout">
      <div className="header-right">
        <div className="home-menu pure-menu pure-menu-horizontal pure-menu-fixed">

          <ul className="pure-menu-list">
            <li className="pure-menu-item">
              <div><FontAwesomeIcon icon={faUser} />&nbsp;{camelize(UserService.getUsername())}</div>
            </li>&nbsp;
            |
            &nbsp;
            <li className="pure-menu-item">
              <div onClick={() => { services.deleteTokenSession();initSession()}}>
                <FontAwesomeIcon icon={faPowerOff} />&nbsp;Salir
              </div>
            </li>
          </ul>
        </div>
      </div>

      <Menu/>

      <AlertSession/>

      <div>
        <Outlet/>
      </div>
      <div className="footer">

        <ul className="heading pure-menu-list">
          <li className="pure-menu-item">
            Versión 2.0
          </li>
        </ul>
      </div>

    </div>
  )
}

export default Home;