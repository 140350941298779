import React from 'react';
import {BrowserView, MobileView, Orientation} from "../DetectDevice";
import AdminTokens from "../../AdminTokens";
import {ImgRotate} from "../ImgRotate"

export const ProxyTokens = () => {
  let isLandScape = Orientation.isLandScape
  return (
    <>
      <BrowserView>
        <AdminTokens/>
      </BrowserView>
      <MobileView>
        {!isLandScape && <ImgRotate/>}
        {isLandScape && <AdminTokens/>}
      </MobileView>
    </>
  )
}