import axios from 'axios';



class InstanceMysqlApis{

  constructor() {
    this.instance = this
  }

  getInstance(){
    return this.instance;
  }

  setToken(token){
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_HOST_APIS+":"+process.env.REACT_APP_PORT_APIS+"/api",
      headers: {
        'Authorization': `Bearer ${token}`}
    })
  }

}

const singletonMysqlApis = new InstanceMysqlApis();



export default singletonMysqlApis;