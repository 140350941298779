import singletonMysqlApis from "./IntanceMysqlApis";
import singletonAxios from "./IntanceAxios";
import { decodeToken } from "react-jwt";
import UserService from "../services/UserService";


export default {
    addRoleAdministrador(id) {
        return singletonAxios.getInstance().post(`/admin/realms/Icintracom/users/${id}/role-mappings/realm`)
    },
    addUser(body) {
        return singletonAxios.getInstance().post("/admin/realms/icintracom/users", body)
    },
    addRole(body) {
        return singletonAxios.getInstance().post('/admin/realms/Icintracom/clients/2758d422-8dff-4b47-9706-40559e829d47/roles', body)
    },
    updateAttributeRole(idRol, body) {
        return singletonAxios.getInstance().put(`/admin/realms/Icintracom/roles-by-id/${idRol}`, body)
    },
    updateActivateUser(idUser, body) {
        return singletonAxios.getInstance().put(`/admin/realms/Icintracom/users/${idUser}`, body)
    },
    getRoleJusCreated(idRol) {
        return singletonAxios.getInstance()
          .get(`/admin/realms/Icintracom/clients/2758d422-8dff-4b47-9706-40559e829d47/roles/${idRol}`)
    },
    getUsersWithRolAssignedMysql() {
        return singletonMysqlApis.getInstance().get(`/users`)
    },
    getModulesByRolMysql() {
        return singletonMysqlApis.getInstance().get(`/roles`)
    },
    getPedidos() {
        return singletonMysqlApis.getInstance().get(`/pedidos`)
    },
    deleteGuia(body){
        return singletonMysqlApis.getInstance().post(`/deleteGuia`, body)
    },
    addUserMysql(body) {
        return singletonMysqlApis.getInstance().post(`/cliente`, body)
    },
    editUserMysql(body) {
        return singletonMysqlApis.getInstance().put(`/edit/cliente`, body)
    },
    deleteUserMysql(body) {
        return singletonMysqlApis.getInstance().delete(`/delete/cliente`, {data: body})
    },
    getUsersMysql() {
        return singletonMysqlApis.getInstance().get(`/clientes`)
    },
    getAllProducts() {
        return singletonMysqlApis.getInstance().get('/inventario')
    },
    getAllTokens() {
        return singletonMysqlApis.getInstance().get(`/getAllTokens`)
    },
    getDate() {
        return singletonMysqlApis.getInstance().get(`/fecha`)
    },
    getUserTokens(body) {
        return singletonMysqlApis.getInstance().post(`/getUserTokens`, body)
    },
    deleteToken(body) {
        return singletonMysqlApis.getInstance().delete(`/deletetoken`, {data: body})
    },
    getRolesClient() {
        return singletonAxios.getInstance()
          .get('/admin/realms/Icintracom/clients/2758d422-8dff-4b47-9706-40559e829d47/roles')
    },
    getUsers() {
        return singletonAxios.getInstance()
          .get('/admin/realms/icintracom/users')
    },
    getRolAssigedByUser() {
        return ""
    },
    getAttributesRoles(id) {
        return singletonAxios.getInstance().get(`/admin/realms/Icintracom/roles-by-id/${id}`)
    },
    getRoleById(id) {
        return singletonAxios.getInstance()
          .get(`admin/realms/Icintracom/users/${id}/role-mappings/clients/2758d422-8dff-4b47-9706-40559e829d47/composite/`)
    },
    getAllRolesAssigned(id) {
        return singletonAxios.getInstance()
          .get(`/admin/realms/Icintracom/users/${id}/role-mappings/clients/2758d422-8dff-4b47-9706-40559e829d47`)
    },
    setRoleUser(idUser, body) {
        return singletonAxios.getInstance()
          .post(`/admin/realms/Icintracom/users/${idUser}/role-mappings/clients/2758d422-8dff-4b47-9706-40559e829d47`, body)
    },
    setRoleRealm(idUser, body) {
        return singletonAxios.getInstance()
          .post(`/admin/realms/Icintracom/users/${idUser}/role-mappings/clients/6c4c80bc-b84e-4c70-add3-6fc2f8803eed`, body)
    },
    deleteRole(id) {
        return singletonAxios.getInstance().delete(`/admin/realms/Icintracom/roles-by-id/${id}`)
    },
    deleteUser(idUser) {
        return singletonAxios.getInstance().delete(`/admin/realms/icintracom/users/${idUser}`)
    },
    deleteRolAssigned(id, body) {
        return singletonAxios.getInstance().delete(`/admin/realms/Icintracom/users/${id}/role-mappings/clients/2758d422-8dff-4b47-9706-40559e829d47`
          , {data: body})
    },
    resetPassword(idUser, body) {
        return singletonAxios.getInstance().put(`/admin/realms/icintracom/users/${idUser}/reset-password`, body)
    },
    updateUser(idUser, body) {
        return singletonAxios.getInstance().put(`/admin/realms/Icintracom/users/${idUser}`, body)
    },
    deleteTokenSession(){
        let token = window.localStorage.getItem("session")
        let myDecodedToken = decodeToken(token);
        let username = myDecodedToken.username
        let access_uuid = myDecodedToken.access_uuid;

        const body = {
            "Username": username,
            "AccessUuid": access_uuid
        }

        this.deleteToken(body).then(response => {
            console.log(response)
            window.localStorage.clear();
            UserService.doLogout()
        }).catch(error =>  console.log(error))
    }
}