/*
   * componente que permite reusar el alert
   * */
import {Alert} from "react-bootstrap";
import React from "react";



const AlertIcintracom = ({title, text, show, close, type}) => {

  //  const [showAlert,setShowAlert] = useState(false)


  return (
    <Alert dismissible
           variant={type}
           show={show}
           onClose={()=>close()}
           style={{margin: "2rem"}}>

      <Alert.Heading>{title}</Alert.Heading>
      <hr/>
      <p>
        {text}
      </p>
    </Alert>
  )
}

export default AlertIcintracom;