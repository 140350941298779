class MenuRoles{
  //produccion
  static menu = {
    'roles':'/AdministracionRoles',
    'usuarios':'/AdministracionUsuarios',
    'tokens':'/AdministracionTokens',
    'existencias':'/Existencias',
    'pedidos': '/Pedidos'
  }

  //pruebas
  /*static menu = {
       'roles':'/pruebasicm/AdministracionRoles',
       'usuarios':'/pruebasicm/AdministracionUsuarios',
       'tokens':'/pruebasicm/AdministracionTokens',
       'existencias':'/pruebasicm/Existencias',
       'pedidos': '/pruebasicm/Pedidos'
   }*/

}

export default MenuRoles;
